var _typeof = require("@babel/runtime/helpers/typeof")["default"];

var assertThisInitialized = require("@babel/runtime/helpers/assertThisInitialized");

function _possibleConstructorReturn(self, call) {
  if (call && (_typeof(call) === "object" || typeof call === "function")) {
    return call;
  }

  return assertThisInitialized(self);
}

module.exports = _possibleConstructorReturn;
module.exports["default"] = module.exports, module.exports.__esModule = true;