"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Doctype = exports.CDATA = exports.Tag = exports.Style = exports.Script = exports.Comment = exports.Directive = exports.Text = exports.Root = exports.isTag = void 0;
/**
 * Tests whether an element is a tag or not.
 *
 * @param elem Element to test
 */
function isTag(elem) {
    return (elem.type === "tag" /* Tag */ ||
        elem.type === "script" /* Script */ ||
        elem.type === "style" /* Style */);
}
exports.isTag = isTag;
// Exports for backwards compatibility
/** Type for the root element of a document */
exports.Root = "root" /* Root */;
/** Type for Text */
exports.Text = "text" /* Text */;
/** Type for <? ... ?> */
exports.Directive = "directive" /* Directive */;
/** Type for <!-- ... --> */
exports.Comment = "comment" /* Comment */;
/** Type for <script> tags */
exports.Script = "script" /* Script */;
/** Type for <style> tags */
exports.Style = "style" /* Style */;
/** Type for Any tag */
exports.Tag = "tag" /* Tag */;
/** Type for <![CDATA[ ... ]]> */
exports.CDATA = "cdata" /* CDATA */;
/** Type for <!doctype ...> */
exports.Doctype = "doctype" /* Doctype */;
